import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Switch from 'react-switch';

import NoVacancy from './NoVacancy';
import excludedDates from './excludedDates';

import capitalize from '../../utils/capitalize';
import postJSON from '../../utils/postJSON';
import toYesNo from '../../utils/toYesNo';

import {
  ADDITIONAL_INFO_LABEL,
  ADDRESS,
  ADDRESS_LABEL,
  ADDRESS_LABEL_FULL,
  ADMINISTER_MEDICATION,
  AFTERNOON_VISIT,
  AFTERNOON_VISITS,
  BOOK_IT,
  BOOK_VISIT_URL,
  CALENDAR_EMOJI,
  CAT_DESCRIPTION,
  CAT_NAME_LABEL_FULL,
  CLOCK_EMOJI,
  COMMENTS_LABEL,
  COMMON_VISITS,
  CONFIRM,
  DROP_OFF_TIME,
  EDIT,
  EMAIL_ADDRESS,
  EMAIL_LABEL,
  EMAIL_LABEL_FULL,
  ENTRY_INSTRUCTIONS,
  ENTRY_INSTRUCTIONS_LABEL,
  EVENING_VISIT,
  EVENING_VISITS,
  EXTRAS_LABEL,
  FROM_LABEL,
  INVALID_LABEL,
  KEY_DROP_OFF,
  KEY_DROP_OFF_ADDRESS,
  KEY_DROP_OFF_LABEL,
  KEY_DROP_OFF_LOCATION,
  KEY_IN_LOCK_BOX,
  KEY_IS_DROPPED_OFF,
  KEY_IS_STASHED,
  KEY_LOCATION,
  KEY_NEEDS_PICK_UP,
  KEY_PICK_UP,
  KEY_PICK_UP_LABEL,
  KEY_PICK_UP_TIME,
  KEY_PICK_UP_TIME_LABEL,
  KEY_STASHED,
  KITTY_QUIRKS_LABEL,
  LOCK_BOX,
  LOCK_BOX_CODE,
  MORNING_VISIT,
  MORNING_VISITS,
  NA,
  NAME,
  NAME_LABEL,
  NAME_LABEL_FULL,
  NONE_GIVEN,
  NOON,
  NOTES,
  OTHER,
  PHONE_LABEL,
  PHONE_LABEL_FULL,
  PHONE_NUMBER,
  REQUEST_ERROR,
  REQUEST_SUCCESS,
  SELECTORS,
  SF_ADDRESSES_ONLY,
  SUMMARY,
  TIME_OF_DAY_LABEL,
  TO_LABEL,
  VET_CONTACT_INFO,
  VET_LABEL,
  VET_LABEL_FULL,
  VISITS,
  VISITS_INTRO,
  VISITS_PRICE,
  WATER_PLANTS,
  WATER_PLANTS_FULL,
  YOUR_ADDRESS,
  YOUR_CATS_NAME,
  YOUR_NAME
} from '../../constants';

export default () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [name, setName] = useState('');
  const [catName, setCatName] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [vetContact, setVetContact] = useState('');
  const [kittyQuirksText, setKittyQuirksText] = useState('');
  const [message, setMessage] = useState('');
  const [isPlants, setIsPlants] = useState(false);
  const [isMorning, setIsMorning] = useState(false);
  const [isAfternoon, setIsAfternoon] = useState(false);
  const [isEvening, setIsEvening] = useState(false);
  const [isMedication, setIsMedication] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isConfirmOverlay, setIsConfirmOverlay] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [overlayContent, setOverlayContent] = useState();
  const [isLockBox, setIsLockBox] = useState(false);
  const [isKeyLocation, setIsKeyLocation] = useState(false);
  const [isKeyPickUp, setIsKeyPickUp] = useState(false);
  const [isKeyDropOff, setIsKeyDropOff] = useState(false);
  const [isOtherEntry, setIsOtherEntry] = useState(true);
  const [lockBoxCode, setLockBoxCode] = useState();
  const [keyLocation, setKeyLocation] = useState();
  const [keyHandOffDate, setKeyHandOffDate] = useState(new Date());
  const [keyHandOffTime, setKeyHandOffTime] = useState();
  const [otherEntry, setOtherEntry] = useState();

  useEffect(() => {
    setKeyHandOffDate(startDate);
  }, [startDate]);

  useEffect(() => {
    if (!isValidating) return;

    const { name, email, phone, address, validity } = SELECTORS;

    const isNameValid = document.querySelector(`${name}${validity}`);
    const isEmailValid = document.querySelector(`${email}${validity}`);
    const isPhoneValid = document.querySelector(`${phone}${validity}`);
    const isAddressValid = document.querySelector(`${address}${validity}`);

    const invalids = [];

    let isDisabled = false;

    if (!isNameValid) {
      invalids.push(NAME);
      isDisabled = true;
    }

    if (!isEmailValid) {
      invalids.push(EMAIL_ADDRESS);
      isDisabled = true;
    }

    if (!isPhoneValid) {
      invalids.push(PHONE_NUMBER);
      isDisabled = true;
    }

    if (!isAddressValid) {
      invalids.push(ADDRESS);
      isDisabled = true;
    }

    setDisabled(isDisabled);
    setInvalidFields(invalids);
  }, [isValidating, name, email, phone, address]);

  useEffect(() => {
    window.history.replaceState({}, 'visits', '/visits');
  }, []);

  const onChangeMorning = checked => setIsMorning(checked);
  const onChangeAfternoon = checked => setIsAfternoon(checked);
  const onChangeEvening = checked => setIsEvening(checked);
  const onChangePlants = checked => setIsPlants(checked);
  const onChangeMedication = checked => setIsMedication(checked);

  const onChangeName = ({ target: { value } }) => setName(value);
  const onChangeCatName = ({ target: { value } }) => setCatName(value);
  const onChangeEmail = ({ target: { value } }) => setEmail(value);
  const onChangePhone = ({ target: { value } }) => setPhone(value);
  const onChangeAddress = ({ target: { value } }) => setAddress(value);
  const onChangeVetContact = ({ target: { value } }) => setVetContact(value);

  const onChangeQuirks = ({ target: { value } }) => setKittyQuirksText(value);
  const onChangeMessage = ({ target: { value } }) => setMessage(value);

  const onChangeIsLockBox = checked => {
    setIsLockBox(checked);

    if (checked) {
      setIsKeyLocation(false);
      setIsKeyPickUp(false);
      setIsKeyDropOff(false);
      setIsOtherEntry(false);

      setKeyLocation(null);
      setKeyHandOffDate(null);
      setKeyHandOffTime(null);
    }
  };

  const onChangeIsKeyLocation = checked => {
    setIsKeyLocation(checked);

    if (checked) {
      setIsLockBox(false);
      setIsKeyPickUp(false);
      setIsKeyDropOff(false);
      setIsOtherEntry(false);

      setLockBoxCode(null);
      setKeyHandOffDate(new Date());
      setKeyHandOffTime(null);
    }
  };

  const onChangeIsKeyDropOff = checked => {
    setIsKeyDropOff(checked);

    if (checked) {
      setIsLockBox(false);
      setIsKeyLocation(false);
      setIsKeyPickUp(false);
      setIsOtherEntry(false);

      setLockBoxCode(null);
      setKeyLocation(null);
      setKeyHandOffDate(new Date());
      setKeyHandOffTime(NOON);
    }
  };

  const onChangeIsKeyPickUp = checked => {
    setIsKeyPickUp(checked);

    if (checked) {
      setIsLockBox(false);
      setIsKeyLocation(false);
      setIsKeyDropOff(false);
      setIsOtherEntry(false);

      setLockBoxCode(null);
      setKeyLocation(null);
      setKeyHandOffDate(new Date());
      setKeyHandOffTime(null);
    }
  };

  const onChangeIsOtherEntry = checked => {
    setIsOtherEntry(checked);

    if (checked) {
      setIsLockBox(false);
      setIsKeyLocation(false);
      setIsKeyPickUp(false);
      setIsKeyDropOff(false);

      setLockBoxCode(null);
      setKeyLocation(null);
      setKeyHandOffDate(new Date());
      setKeyHandOffTime(null);
    }
  };

  const onChangeLockBoxCode = ({ target: { value } }) => setLockBoxCode(value);
  const onChangeKeyLocation = ({ target: { value } }) => setKeyLocation(value);
  const onChangeOtherEntry = ({ target: { value } }) => setOtherEntry(value);
  const onChangeHandOffTime = ({ target: { value } }) =>
    setKeyHandOffTime(value);

  const payload = {
    name,
    catName,
    startDate: startDate.toString().substring(0, 15),
    endDate: endDate.toString().substring(0, 15),
    isMorning,
    isAfternoon,
    isEvening,
    isMedication,
    isPlants,
    kittyQuirksText,
    message,
    email,
    phone,
    address,
    vetContact,
    lockBoxCode: isLockBox ? lockBoxCode : NA,
    keyLocation: isKeyLocation ? keyLocation : NA,
    keyPickUpDate: isKeyPickUp
      ? keyHandOffDate.toString().substring(0, 15)
      : NA,
    keyPickUpTime: isKeyPickUp ? keyHandOffTime : NA,
    keyDropOffDate: isKeyDropOff
      ? keyHandOffDate.toString().substring(0, 15)
      : NA,
    keyDropOffTime: isKeyDropOff ? NOON : NA,
    otherEntry: isOtherEntry ? otherEntry : NA
  };

  const onClickBook = () => {
    setIsConfirmOverlay(true);
    setIsValidating(true);
  };

  const onClickClose = () => setIsConfirmOverlay(false);

  const onClickSubmit = async () => {
    if (!disabled) {
      setDisabled(true);

      const { success } = await postJSON(BOOK_VISIT_URL, payload);

      if (success) {
        setOverlayContent(REQUEST_SUCCESS);
        setDisabled(true);

        return;
      }

      setOverlayContent(REQUEST_ERROR);
    }
  };

  return (
    <>
      {isConfirmOverlay && (
        <aside id="confirm" className={overlayContent ? 'success' : ''}>
          <button id="back-button" onClick={onClickClose}>
            ⬅
          </button>
          {overlayContent || (
            <>
              <h4>{SUMMARY}</h4>
              {invalidFields?.length ? (
                <p className="error">
                  {INVALID_LABEL} {invalidFields.join(', ')}.
                </p>
              ) : (
                ''
              )}
              <div>
                <strong>{NAME_LABEL}</strong>&nbsp;
                <div>{name}</div>
              </div>
              <div>
                <strong>{FROM_LABEL}</strong>&nbsp;
                <div>{startDate.toLocaleDateString()}</div>
              </div>
              <div>
                <strong>{TO_LABEL}</strong>&nbsp;
                <div>{endDate.toLocaleDateString()}</div>
              </div>
              <div>
                <strong>{MORNING_VISITS}</strong>&nbsp;
                <div>{toYesNo(isMorning)}</div>
              </div>
              <div>
                <strong>{AFTERNOON_VISITS}</strong>&nbsp;
                <div>{toYesNo(isAfternoon)}</div>
              </div>
              <div>
                <strong>{EVENING_VISITS}</strong>&nbsp;
                <div>{toYesNo(isEvening)}</div>
              </div>
              <div>
                <strong>{WATER_PLANTS}</strong>&nbsp;
                <div>{toYesNo(isPlants)}</div>
              </div>
              <div>
                <strong>{KITTY_QUIRKS_LABEL}</strong>&nbsp;
                <div>{kittyQuirksText}</div>
              </div>
              <div>
                <strong>{ADDITIONAL_INFO_LABEL}</strong>&nbsp;
                <div>{message}</div>
              </div>
              <div>
                <strong>{EMAIL_LABEL_FULL}</strong>&nbsp;
                <div>{email}</div>
              </div>
              <div>
                <strong>{PHONE_LABEL_FULL}</strong>&nbsp;
                <div>{phone}</div>
              </div>
              <div>
                <strong>{ADDRESS_LABEL_FULL}</strong>&nbsp;
                <div>{address}</div>
              </div>
              <div>
                <strong>{VET_LABEL_FULL}</strong>&nbsp;
                <div>{vetContact}</div>
              </div>
              <div>
                <strong>{ENTRY_INSTRUCTIONS_LABEL}</strong>&nbsp;
                <div>
                  {isLockBox
                    ? LOCK_BOX
                    : isKeyLocation
                      ? KEY_STASHED
                      : isKeyPickUp
                        ? KEY_PICK_UP
                        : isKeyDropOff
                          ? KEY_DROP_OFF
                          : isOtherEntry
                            ? otherEntry
                            : NONE_GIVEN}
                </div>
              </div>
              <button id="submit" onClick={onClickSubmit} disabled={disabled}>
                {CONFIRM}
              </button>
              <button id="close" onClick={onClickClose}>
                {EDIT}
              </button>
            </>
          )}
        </aside>
      )}
      <h1>{VISITS}</h1>
      <p className="intro">{VISITS_INTRO}</p>
      <p>
        <strong>{VISITS_PRICE}</strong>
      </p>
      <NoVacancy />
      <div className="datepicker-group">
        <h5>{FROM_LABEL}</h5>
        <div className="datepicker-label-group">
          <div>{CALENDAR_EMOJI}</div>
          <DatePicker
            excludeDates={excludedDates}
            selected={startDate}
            onChange={date => setStartDate(date)}
          />
        </div>
      </div>
      <div className="datepicker-group">
        <h5>{TO_LABEL}</h5>
        <div className="datepicker-label-group">
          <div>{CALENDAR_EMOJI}</div>
          <DatePicker
            excludeDates={excludedDates}
            selected={endDate}
            onChange={date => setEndDate(date)}
          />
        </div>
      </div>
      <div className="control">
        <h5>{TIME_OF_DAY_LABEL}</h5>
        <p className="disclaimer">{COMMON_VISITS}</p>
        <div className="label-group">
          <label className="react-switch-label">
            <span>{MORNING_VISIT}</span>
            <Switch
              onChange={onChangeMorning}
              checked={isMorning}
              className="react-switch"
              onColor="#9418ff"
              offColor="#8a78a1"
              checkedIcon={false}
              uncheckedIcon={false}
              width={40}
              height={20}
            />
          </label>
        </div>
        <div className="label-group">
          <label className="react-switch-label">
            <span>{AFTERNOON_VISIT}</span>
            <Switch
              onChange={onChangeAfternoon}
              checked={isAfternoon}
              className="react-switch"
              onColor="#9418ff"
              offColor="#8a78a1"
              checkedIcon={false}
              uncheckedIcon={false}
              width={40}
              height={20}
            />
          </label>
        </div>
        <div className="label-group">
          <label className="react-switch-label">
            <span>{EVENING_VISIT}</span>
            <Switch
              onChange={onChangeEvening}
              checked={isEvening}
              className="react-switch"
              onColor="#9418ff"
              offColor="#8a78a1"
              checkedIcon={false}
              uncheckedIcon={false}
              width={40}
              height={20}
            />
          </label>
        </div>
      </div>
      <div className="contact panel full">
        <h5>{ADDRESS_LABEL}</h5>
        <input
          id="address"
          type="text"
          onChange={onChangeAddress}
          placeholder={SF_ADDRESSES_ONLY}
          value={address}
          pattern="[a-zA-Z0-9\s\W+]{2,}"
          required={isValidating}
        />
      </div>
      <h5>{KITTY_QUIRKS_LABEL}</h5>
      <textarea
        value={kittyQuirksText}
        onChange={onChangeQuirks}
        placeholder={CAT_DESCRIPTION}
      />
      <h5>{EXTRAS_LABEL}</h5>
      <div className="label-group">
        <label className="react-switch-label">
          <span>{ADMINISTER_MEDICATION}</span>
          <Switch
            onChange={onChangeMedication}
            checked={isMedication}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      <div className="label-group">
        <label className="react-switch-label">
          <span>{WATER_PLANTS_FULL}</span>
          <Switch
            onChange={onChangePlants}
            checked={isPlants}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      <div className="contact">
        <h5>{NAME_LABEL_FULL}</h5>
        <input
          id="name"
          type="text"
          placeholder={YOUR_NAME}
          required={isValidating}
          value={name}
          onChange={onChangeName}
        />
      </div>
      <div className="contact">
        <h5>{CAT_NAME_LABEL_FULL}</h5>
        <input
          id="cat-name"
          type="text"
          placeholder={YOUR_CATS_NAME}
          value={catName}
          onChange={onChangeCatName}
        />
      </div>
      <div className="contact">
        <h5>{EMAIL_LABEL}</h5>
        <input
          id="email"
          type="email"
          placeholder={capitalize(EMAIL_ADDRESS)}
          required={isValidating}
          value={email}
          onChange={onChangeEmail}
        />
      </div>
      <div className="contact">
        <h5>{PHONE_LABEL}</h5>
        <input
          id="phone"
          type="tel"
          pattern="[a-zA-Z0-9\s\W+]{2,}"
          placeholder={capitalize(PHONE_NUMBER)}
          required={isValidating}
          value={phone}
          onChange={onChangePhone}
        />
      </div>
      <div className="contact">
        <h5>{VET_LABEL}</h5>
        <input
          type="text"
          placeholder={VET_CONTACT_INFO}
          value={vetContact}
          onChange={onChangeVetContact}
        />
      </div>
      <h5>{ENTRY_INSTRUCTIONS_LABEL}</h5>
      <div className="label-group">
        <label className="react-switch-label">
          <span>{KEY_IN_LOCK_BOX}</span>
          <Switch
            onChange={onChangeIsLockBox}
            checked={isLockBox}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      {isLockBox && (
        <p>
          <input
            type="text"
            placeholder={LOCK_BOX_CODE}
            value={lockBoxCode}
            onChange={onChangeLockBoxCode}
          />
        </p>
      )}
      <div className="label-group">
        <label className="react-switch-label">
          <span>{KEY_IS_STASHED}</span>
          <Switch
            onChange={onChangeIsKeyLocation}
            checked={isKeyLocation}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      {isKeyLocation && (
        <p>
          <input
            type="text"
            placeholder={KEY_LOCATION}
            value={keyLocation}
            onChange={onChangeKeyLocation}
          />
        </p>
      )}
      <div className="label-group">
        <label className="react-switch-label">
          <span>{KEY_IS_DROPPED_OFF}</span>
          <Switch
            onChange={onChangeIsKeyDropOff}
            checked={isKeyDropOff}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      {isKeyDropOff && (
        <div className="control">
          <input
            type="text"
            placeholder={KEY_DROP_OFF_LOCATION}
            value={KEY_DROP_OFF_ADDRESS}
            disabled
          />
          <p className="disclaimer">{DROP_OFF_TIME}</p>
          <div className="datepicker-group panel full">
            <h5>{KEY_DROP_OFF_LABEL}</h5>
            <div className="datepicker-label-group">
              <div>{CALENDAR_EMOJI}</div>
              <DatePicker
                selected={keyHandOffDate}
                onChange={date => setKeyHandOffDate(date)}
              />
            </div>
          </div>
        </div>
      )}
      <div className="label-group">
        <label className="react-switch-label">
          <span>{KEY_NEEDS_PICK_UP}</span>
          <Switch
            onChange={onChangeIsKeyPickUp}
            checked={isKeyPickUp}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      {isKeyPickUp && (
        <div className="control">
          <input
            type="text"
            placeholder={YOUR_ADDRESS}
            value={address}
            onChange={onChangeAddress}
            disabled
          />
          <div className="datepicker-group panel full">
            <h5>{KEY_PICK_UP_LABEL}</h5>
            <div className="datepicker-label-group">
              <div>{CALENDAR_EMOJI}</div>
              <DatePicker
                selected={keyHandOffDate}
                onChange={date => setKeyHandOffDate(date)}
              />
            </div>
            <h5>{KEY_PICK_UP_TIME_LABEL}</h5>
            <div className="datepicker-label-group">
              <div>{CLOCK_EMOJI}</div>
              <input
                type="text"
                className="time-picker"
                value={keyHandOffTime}
                onChange={onChangeHandOffTime}
                placeholder={KEY_PICK_UP_TIME}
                pattern="[a-zA-Z0-9\s]{2,}"
              />
            </div>
          </div>
        </div>
      )}
      <div className="label-group">
        <label className="react-switch-label">
          <span>{OTHER}</span>
          <Switch
            onChange={onChangeIsOtherEntry}
            checked={isOtherEntry}
            className="react-switch"
            onColor="#9418ff"
            offColor="#8a78a1"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={20}
          />
        </label>
      </div>
      {isOtherEntry && (
        <div className="control">
          <input
            type="text"
            placeholder={ENTRY_INSTRUCTIONS}
            value={otherEntry}
            onChange={onChangeOtherEntry}
          />
        </div>
      )}
      <h5>{COMMENTS_LABEL}</h5>
      <textarea
        value={message}
        onChange={onChangeMessage}
        placeholder={NOTES}
      />
      <button
        id="book"
        onClick={onClickBook}
        disabled={disabled || isConfirmOverlay}
      >
        {BOOK_IT}
      </button>
    </>
  );
};
