/**
 * Exclude specific dates here.
 * These will be disabled in the datepicker
 * on the lead form.
 */

export default [
  new Date('November 1, 2024'),
  new Date('November 2, 2024'),
  new Date('November 3, 2024'),
  new Date('November 4, 2024'),
  new Date('November 5, 2024'),
  new Date('November 6, 2024'),
  new Date('November 7, 2024'),
  new Date('November 8, 2024'),
  new Date('November 9, 2024'),
  new Date('November 10, 2024'),
  new Date('November 11, 2024'),
  new Date('November 12, 2024'),
  new Date('November 13, 2024'),
  new Date('November 14, 2024'),
  new Date('November 15, 2024'),
  new Date('November 16, 2024'),
  new Date('November 17, 2024'),
  new Date('November 18, 2024'),
  new Date('November 19, 2024'),
  new Date('November 20, 2024'),
  new Date('November 21, 2024'),
  new Date('November 22, 2024'),
  new Date('November 23, 2024'),
  new Date('November 24, 2024'),
  new Date('November 25, 2024'),
  new Date('November 26, 2024'),
  new Date('November 27, 2024'),
  new Date('November 28, 2024'),
  new Date('November 29, 2024'),
  new Date('November 30, 2024'),
  new Date('December 1, 2024'),
  new Date('December 2, 2024'),
  new Date('December 3, 2024'),
  new Date('December 4, 2024'),
  new Date('December 5, 2024'),
  new Date('December 6, 2024'),
  new Date('December 7, 2024'),
  new Date('December 8, 2024'),
  new Date('December 9, 2024'),
  new Date('December 10, 2024'),
  new Date('December 11, 2024'),
  new Date('December 12, 2024'),
  new Date('December 13, 2024'),
  new Date('December 14, 2024'),
  new Date('December 15, 2024'),
  new Date('December 16, 2024'),
  new Date('December 17, 2024'),
  new Date('December 18, 2024'),
  new Date('December 19, 2024'),
  new Date('December 20, 2024'),
  new Date('December 21, 2024'),
  new Date('December 22, 2024'),
  new Date('December 23, 2024'),
  new Date('December 24, 2024'),
  new Date('December 25, 2024'),
  new Date('December 26, 2024'),
  new Date('December 27, 2024'),
  new Date('December 28, 2024'),
  new Date('December 29, 2024'),
  new Date('December 30, 2024'),
  new Date('December 31, 2024'),
  new Date('January 1, 2025'),
  new Date('January 2, 2025'),
  new Date('January 3, 2025'),
  new Date('January 4, 2025'),
  new Date('January 5, 2025')
];
